import userInfo from "@/common/userInfo";

let userHash = localStorage.getItem("usrha")
let firstName = localStorage.getItem("firstName")
let lastName = localStorage.getItem("lastName")
let avatar = localStorage.getItem("avatar")
let email = localStorage.getItem("email")
let created_at = localStorage.getItem("created_at")
let uid = localStorage.getItem("uid")
let jwt = localStorage.getItem("nvg_auth")
let codeLang = localStorage.getItem('codeLang')
let dict = {}
try {
    dict[codeLang] = JSON.parse(localStorage.getItem('dict'))
}
catch (e) {
    console.error("dicteccodelang")
}

function pushTagManager(window) {
    window.dataLayer?.push({
        event: "Login",
        email: userInfo.email,
        name: userInfo.firstName + " " + userInfo.lastName,
        user_id: userInfo.uid,
        created_at: userInfo.created_at,
        avatar: userInfo.avatar,
    })
    return window.dataLayer
}


export default { userHash, firstName, lastName, jwt, avatar, uid, dict, codeLang, created_at, email, pushTagManager}