<template>
  <div class="mb-16 pb-16">
    <Navbar />

    <div class="grid xl:grid-cols-12 mt-20">
      <div class="xl:col-start-3 col-span-12 xl:col-span-10 p-5 xl:p-12">
        <div class="grid xl:grid-cols-12 gap-6">
          <div class="xl:col-span-4">
            <div class="flex items-center justify-center gap-6 flex-col bg-white filter drop-shadow-sm
                rounded-lg p-8">
              <img :src="this.user.avatar" :alt="this.user.email + ' Photo'" class="rounded-full w-32 h-32" />

              <div>
                <div class="flex justify-center font-bold text-lg">
                  {{this.user.firstName}} {{this.user.lastName}}
                </div>
                <div v-if="this.user.position && this.user.company"
                     class="tt-text-blue font-medium text-center text-sm mt-1">
                  {{this.user.position}} {{$t('at2')}} {{this.user.company}}
                </div>
              </div>
            </div>

            <div v-if="resInfo.pData"
                class="flex items-center justify-center flex-col bg-white filter drop-shadow-sm
                rounded-lg p-4 mt-4">
              <Progress place="profile" :pData="resInfo.pData"></Progress>
            </div>

          </div>
          <div class="xl:col-span-8">
            <div class="flex items-start justify-center gap-4 flex-col bg-white filter drop-shadow-sm
                 rounded-lg p-2">
              <ButtonRouter class="rounded-2xl transition hover:bg-gray-100 w-full gap-3"
                            href="/settings"
                            flex="flex items-center justify-start"
                            size="p-3">
                <div class="w-12 h-12 flex items-center justify-center rounded-full bg-gray4 tt-text-blue">
                  <CogIcon class="w-6 h-6" />
                </div>
                <div class="flex items-start justify-center gap-1 flex-col">
                  <strong class="text-base">{{$t('setigifdjgn')}}</strong>
                  <span class="text-gray3 font-light text-sm">{{$t('setigifdjgn2')}}</span>
                </div>
              </ButtonRouter>

<!--              <a href="#" class="flex items-center justify-start gap-3-->
<!--              rounded-2xl transition hover:bg-gray-100 w-full p-3">-->
<!--              <div class="w-12 h-12 flex items-center justify-center rounded-full bg-gray4 tt-text-blue">-->
<!--                <UserGroupIcon class="w-6 h-6" />-->
<!--              </div>-->

<!--              <div class="flex items-start justify-center gap-1 flex-col">-->
<!--                <strong class="text-base">{{$t('tewmadasmfq1')}}</strong>-->
<!--                <span class="text-gray3 font-light text-sm">{{$t('tewmadasmfq2')}}</span>-->
<!--              </div>-->
<!--              </a>-->

<!--              <a href="#" class="flex items-center justify-start gap-3-->
<!--              rounded-2xl transition hover:bg-gray-100 w-full p-3">-->
<!--              <div class="w-12 h-12 flex items-center justify-center rounded-full bg-gray4 tt-text-blue">-->
<!--                <LockOpenIcon class="w-6 h-6" />-->
<!--              </div>-->

<!--              <div class="flex items-start justify-center gap-1 flex-col">-->
<!--                <strong class="text-base">{{$t('changepqda1')}}</strong>-->
<!--                <span class="text-gray3 font-light text-sm">{{$t('changepqda12')}}</span>-->
<!--              </div>-->
<!--              </a>-->

              <Button class="gap-3 rounded-2xl transition hover:bg-gray-100 w-full p-3"
                      flex="flex items-center justify-start"
                      @wasClicked="dologout()" v-if="user.firstName">
                <span class="w-12 h-12 flex items-center justify-center rounded-full tt-text-blue">
                    <LogoutIcon class="w-6 h-6" />
                </span>

                <span class="flex items-start justify-center gap-1 flex-col">
                  <strong class="text-base">{{$t('vititit')}}</strong>
                  <span class="text-gray3 font-light text-sm">{{$t('vititit2')}}</span>
                </span>
              </Button>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import userInfo from "@/common/userInfo";
import nvgpost from "@/common/nvgpost";
import Progress from "@/components/Static/Progress";
import { LogoutIcon, CogIcon,
  //LockOpenIcon, UserGroupIcon
} from "@vue-hero-icons/outline";
import Button from "@/components/Inter/Button";
import logout from "@/common/logout";
import ButtonRouter from "@/components/Inter/ButtonRouter";


export default {
  name: "Me",
  components: {
    ButtonRouter, Button, Progress, Navbar, LogoutIcon, CogIcon, //LockOpenIcon, UserGroupIcon
    },
  methods: {
    dologout: function () {
      this.user = {}
      logout()
      window.location.reload()
    },
  },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      resInfo: {},
      user: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
        jwt: "",
        position: "",
        company: "",
      },
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)

    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")

      nvgpost("https://api.xy.md/v1/course/info",
          "POST",
          {
            'lang': that.codeLang,
            'courseId': 1,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res.jwt) {
              that.resInfo = res.data.info
            }
          }, true)
    }, 1000)

    nvgpost("https://api.xy.md/v1/users/info",
        "POST",
        {},
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=> {
          that.user = res
        })
  },
}
</script>