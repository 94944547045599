<template>
  <div class="transform transition fixed top-8 right-8 bg-white border shadow-sm z-60 px-4 py-5"
       :class="toShow ? '' : '-translate-y-48'">
    <span :class="messageType===1 ? 'tt-text-green' : 'text-red-400'">
      {{ this.showMessage }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      toShow: false,
      showMessage: "",
      messageType: 1,
    }
  },
  mounted() {
    let that = this
    this.$root.$on('toShowTrigger', (text, type) => {
      that.toShow = true
      that.showMessage = text
      that.messageType = type
      setTimeout(function () {
        that.toShow = false
        that.showMessage = ""
        that.messageType = 1
      },5000)
    })
  }
}
</script>

<style scoped>
.z-60 {
  z-index: 60;
}
</style>