<template>
  <div class="fixed top-32 w-96 z-20 bg-white rounded-lg p-6">
    <ButtonRouter :href="'/sign-in'" class="w-full tt-bg-blue text-white">{{$t('startlesson')}}</ButtonRouter>
    <div class="mt-8">
      <span class="font-bold">{{$t('ctrdariej')}}</span>
    </div>
    <div class="mt-4">
      <ul class="list-disc" v-html="gives"></ul>
    </div>
  </div>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";


export default {
  name: "CourseWhiteBadge",
  components: {
    ButtonRouter,
  },
  props: {
    gives: String,
    courseId: [Number, String],
  },
}
</script>