<template>
  <div id="app">
    <router-view :key="randomId" />

    <Alert />

    <div class="fixed bottom-2 left-2 z-30" style="font-size: 5px; color: #c0c0c0">
      v{{ version }}
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Block/Alert";
import userInfo from "@/common/userInfo"
import getUserInfo from "@/common/getUserInfo"
import md5 from 'md5/md5'


export default {
  name: 'App',
  data() {
    return {
      version: "0.0.1",
      newVersion: false,
      supportModal: false,
    }
  },
  components: {
    Alert,
  },
  computed: {
    randomId () {
      return this.$route.fullPath + new Date()
    },
  },
  methods: {
    douEm: function () {
      if(localStorage.getItem("email"))
      {
        window.dataLayer?.push({
          event: "Login",
          email: localStorage.getItem("email"),
          name: localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"),
          user_id: localStorage.getItem("uid"),
          created_at: localStorage.getItem("created_at"),
          avatar: localStorage.getItem("avatar"),
        })
      }
      else {
        setTimeout(this.douEm, 1000)
      }
    },
  },
  mounted() {
    this.$root.$on("douEm", function () {
      this.douEm()
      return true
    }.bind(this))

    if((!userInfo.userHash || !userInfo.firstName || !userInfo.lastName)
        || (userInfo.userHash
          && md5(userInfo.firstName+userInfo.lastName+userInfo.jwt) !== userInfo.userHash)) {
        if(localStorage.getItem("nvg_auth"))
        {
          getUserInfo()
          this.douEm()
        }
        else
        {
          setTimeout(function () {
            getUserInfo()
          },1000)
        }
    }
  },
}
</script>