<template>
  <label class="nvgcheck w-full flex items-center gap-2">
    <input :name="nameRadio" class="nvgcheck rounded-full flex-none" type="radio"
           :disabled="!!disabledVar"
           :checked="checkedVar" :value="theval">
    <span class="block font-semibold text-base">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "NVGRadio",
  props: {
    checkedVar: [String, Number, Boolean],
    disabledVar: [String, Number, Boolean],
    nameRadio: String,
    theval: [String, Number, Boolean]
  }
}
</script>