<template>
  <nav class="px-2 xl:px-4 py-4 fixed top-0 left-0 w-full z-20
   xl:grid xl:grid-cols-12 gap-3 flex items-center justify-between bg-white"
       id="navbar">
    <Logo class="xl:col-span-2 xl:col-start-2" />
    <MobMenu class="xl:hidden" />
    <div class="xl:col-span-8 xl:col-end-12 flex hidden xl:flex justify-self-end justify-end gap-2">
      <Links />
      <Naver class="xl:flex justify-self-end justify-end gap-2" />
    </div>

  </nav>
</template>

<script>
import Logo from "@/components/Static/Logo";
import MobMenu from "@/components/Control/MobMenu";
import Links from "@/components/Control/Links";
import Naver from "@/components/Control/Naver";


export default {
  name: "Navbar",
  components: {
    Naver,
    Links,
    MobMenu,
    Logo,
  },
}
</script>