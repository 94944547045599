<template>
  <label class="nvgcheck w-full flex items-center gap-2">
    <input class="nvgcheck rounded-lg" type="checkbox" :checked="checkedVar" @change="onChangeFunc($event)">
    <span class="block font-semibold text-base">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: "NVGCheck",
  props: {
    checkedVar: [String, Number, Boolean],
    onChangeFunc: Function,
  }
}
</script>