<template>
  <select class="cursor-pointer bg-transparent !ml-4 border rounded-lg px-4 py-3" @change="changeLang" :id="sid">
    <option value="ru" :selected="this.codeLang==='ru'">Ru</option>
    <option value="ro" :selected="this.codeLang==='ro'">Ro</option>
    <option value="en" :selected="this.codeLang==='en'">En</option>
  </select>
</template>

<script>
import userInfo from "@/common/userInfo";

export default {
  name: "ChangeLang",
  props: {
    sid: {
      type: String,
      default: "langchecker",
    },
  },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang")
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 1000)
  },
  methods: {
    changeLang: function (e) {
      userInfo.codeLang = e.target.value
      localStorage.setItem("codeLang", userInfo.codeLang)
      localStorage.removeItem("dict")
      if(this.sid==="langchecker") {
        window.location.reload()
      }
    },
  }
}
</script>