<template>
  <section class="grid xl:grid-cols-12 items-center gap-14 xl:gap-3 tt-bg-blackblue pt-10 pb-24 px-4 xl:px-8">
    <div class="xl:col-span-6 xl:col-start-2 flex items-start justify-center gap-3.5 flex-col">
      <Badge>{{$t('free')}}</Badge>
      <h1 class="font-bold text-2xl xl:text-4xl text-white leading-normal">
        {{ resInfo.title }}
      </h1>
      <p class="text-white mt-3">
        {{ resInfo.description }}
      </p>
      <span class="text-white mt-4">
        <span>{{$t('authors')}}: </span>
        <span class="tt-text-darkblue cursor-pointer">{{resInfo.authors}}</span>
      </span>
    </div>

    <div class="hidden xl:block xl:col-span-4 xl:col-end-12 xl:px-12 xl:mx-2">
      <Progress v-if="resInfo.pData" :pData="resInfo.pData" />
      <CourseWhiteBadge v-else :courseId="resInfo.id" :gives="resInfo.gives" />
    </div>
  </section>
</template>

<script>
import Badge from "@/components/Static/Badge";
import Progress from "@/components/Static/Progress";
import CourseWhiteBadge from "@/components/Static/CourseWhiteBadge";


export default {
  name: "Intro",
  components: {Progress, Badge, CourseWhiteBadge},
  props: {
    resInfo: {
      type: Object,
      default: () => {
        return {
          pData: {},
          authors: "",
          title: "",
          description: "",
          id: 0,
          lis: "",
          gives: "",
        }
      }
    }
  }
}
</script>