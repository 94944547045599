<template>
  <section class="grid xl:grid-cols-12 xl:px-8">
    <div :class="isPublic ? 'xl:col-span-6' : 'xl:col-span-10'"
         class="xl:col-start-2 bg-white rounded-lg shadow-md px-5 xl:px-8 py-6">
      <div class="flex items-start xl:items-center justify-between gap-4 flex-col xl:flex-row font-bold">
        <span class="text-base">
          {{$t('materialsdcoure')}}
        </span>
        <div>
          <Button size="0" @wasClicked="collapsePlaces('collapseModule')"
                  class="text-sm tt-text-darkblue">
            {{$t('radazenvrrueit')}}
          </Button>
        </div>
      </div>

      <p class="text-sm mt-8 xl:mt-2" v-if="resMaterials.modules">
        {{Object.keys(resMaterials.modules).length}} {{$t('mdosaues22')}}
        • {{resMaterials.lessonsCount}} {{$t('elctririi')}}
      </p>

      <div class="collapseModule border rounded-lg p-4 mt-5 max-h-full overflow-hidden transition-all"
           :id="'module'+index"
           :key="'module'+index"
           v-for="(value, index) in resMaterials.modules">
        <Button size="0" @wasClicked="collapsePlace(['module', 'arrow'], index)" class="w-full"
                flex="items-start justify-center flex-col">
          <div class="flex items-center justify-between text-sm w-full">
            <span class="font-bold">
              {{value[0]}}
            </span>
            <ChevronDownIcon class="hidden xl:block w-4 h-4 transform transition" :id="'arrow'+index" />
          </div>

          <div class="text-sm mt-4">
            {{value[1]}}
          </div>
        </Button>

        <div v-for="(lesson, index2) in resMaterials.lessons[index]" :key="'lesson'+index2">
          <ButtonRouter :href="lesson.type === 'lesson' ? '/lessons/' + lesson.lid : '/quiz/' + lesson.id
          + (!!lesson.stat ? '/result' : '')"
                        size="p-4"
                        flex="flex items-start xl:items-center justify-between flex-col xl:flex-row"
                        class="border rounded-lg mt-5 overflow-hidden w-full gap-4">
            <div class="grid grid-cols-12 w-full items-center justify-start gap-3.5 overflow-hidden">
              <div class="col-span-4 xl:col-span-2 flex items-center justify-start gap-3.5 flex-row overflow-hidden">
                <CheckCircleIcon v-if="!!lesson.stat" class="self-start w-5 h-5 tt-text-darkblue flex-none" />
                <NewspaperIcon v-if="lesson.type==='lesson'" class="self-start w-5 h-5 flex-none" />
                <PuzzleIcon v-else-if="lesson.type==='quiz'" class="self-start w-5 h-5" />
                <span class="hidden xl:block" v-if="lesson.type==='lesson'">{{$t('lesson')}}</span>
                <span class="hidden xl:block" v-else-if="lesson.type==='quiz'">{{$t('quiz')}}</span>
              </div>
              <span class="col-span-8 xl:col-span-10">
                <span v-if="lesson.type=='lesson'">
                  {{lesson.name}}
                </span>
                <span v-else-if="lesson.type=='quiz'">
                  {{lesson.tot}} {{$t('vorpdoov')}}
                </span>
              </span>
            </div>
            <div class="hidden xl:block cursor-pointer text-sm font-normal tt-text-darkblue" v-if="!isPublic">
              {{$t('otktitit')}}
            </div>
          </ButtonRouter>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {ChevronDownIcon, NewspaperIcon, CheckCircleIcon, PuzzleIcon} from "@vue-hero-icons/outline";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import Button from "@/components/Inter/Button";
import animations from "@/common/animations";


export default {
  name: "Materials",
  components: {
    Button,
    ButtonRouter,
    ChevronDownIcon, NewspaperIcon, CheckCircleIcon, PuzzleIcon
  },
  props: ["resMaterials", "isPublic"],
  methods: {
    collapsePlace: animations.collapsePlace,
    collapsePlaces: animations.collapsePlaces,
  }
}
</script>