<template>
  <div>
    <div class="grid grid-cols-12 xl:h-screen">
      <div class="xl:col-span-6 col-span-12 overflow-hidden">
        <img src="../assets/landing/authimage.png" alt="Login image"
             class="w-full h-96 xl:h-full object-cover select-none">
      </div>
      <div class="xl:col-start-8 xl:col-span-4 col-span-12 mt-10 xl:mt-0 pb-48 xl:pb-0 px-6 xl:px-0
      flex items-start justify-center flex-col pt-16">
        <router-link to="/" class="flex items-center gap-2">
          <img src="../assets/logo.svg" alt="Logo XY Academy" class="w-32">
        </router-link>

        <h1 class="text-2xl font-bold mt-5">
          {{$t('regirsta')}}
        </h1>

        <div class="w-full mt-6 mb-24">
          <div>
            <label class="text-gray-400 block">{{$t('sseconame')}}</label>
            <Input id="firstName" type="text" itype="Lg" class="w-full mt-3" placeholder="John" />
          </div>
          <div class="mt-5">
            <label class="text-gray-400 block">{{$t('sseconame2')}}</label>
            <Input id="secondName" type="text" itype="Lg" class="w-full mt-3" placeholder="Snow" />
          </div>
          <div class="mt-5">
            <label class="text-gray-400 block">{{$t('sexscas')}}</label>
            <select id="gender"
                    class="text-sm px-6 py-4 rounded-xl border focus:tt-border-blue text-gray-400 mt-3 w-full">
              <option value="1">{{$t('madlsaew1')}}</option>
              <option value="0">{{$t('madlsaew2')}}</option>
              <option value="2">{{$t('madlsaew3')}}</option>
            </select>
          </div>
          <div class="mt-5">
            <label class="text-gray-400 block">Email</label>
            <Input id="email" type="email" itype="Lg" class="w-full mt-3" placeholder="John.snow@gmail.com" />
          </div>
          <div class="mt-5">
            <label class="text-gray-400 block">{{$t('psadapsdwqd')}}</label>
            <Input id="password" type="password" itype="Lg" class="w-full mt-3" placeholder="*********" minlength="6" />
          </div>
          <div class="mt-5">
            <label class="text-gray-400 block">{{$t('psadapsdwqd2')}}</label>
            <Input id="password2" type="password" itype="Lg" class="w-full mt-3" placeholder="*********" minlength="6" />
          </div>

          <label class="inline-flex items-center gap-2 mt-8 rounded-xl">
            <input type="checkbox" id="iread" />
            <span class="ml-2">{{$t('trmensada')}} <router-link class="tt-text-blue" to="/terms">{{$t('trmensada2')}}</router-link>
              {{$t('trmensada3')}} <router-link class="tt-text-blue" to="/privacy">{{$t('trmensada4')}}</router-link></span>
          </label>

          <div class="mt-8">
            <Button @wasClicked="Registration()" class="bg-black text-white w-full" size="Lg">
              {{$t('regirsta')}}
            </Button>
            <ButtonRouter href="/sign-in" class="bg-white text-black border border-black w-full mt-6" size="py-4 px-6">
              {{$t('entetir')}}
            </ButtonRouter>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import getUserInfo from "@/common/getUserInfo";
import nvgpost from "@/common/nvgpost";
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";
import ButtonRouter from "@/components/Inter/ButtonRouter";


export default {
  name: "SignUp",
  components: {ButtonRouter, Button, Input},
  data() {
    return {
      codeLang: localStorage.getItem("codeLang")
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 1000)
  },
  methods: {
    Registration: function () {
      let fname = document.getElementById("firstName").value
      let lname = document.getElementById("secondName").value
      let email = document.getElementById("email").value
      let password = document.getElementById("password").value
      let password2 = document.getElementById("password2").value
      let gender = document.getElementById("gender").value
      let iread = document.getElementById("iread").checked

      if(!iread) {
        this.$root.$emit("toShowTrigger", this.$root.$t('noread'), 2)
        return false
      }


      if(password===password2 && fname && lname && email && password && gender) {
        nvgpost("https://api.xy.md/v1/sign-up",
            "POST",
            {
              'firstName': fname,
              'secondName': lname,
              'gender': gender,
              'email': email,
              'password': password,
              'password2': password2,
              'lang': this.codeLang,
            },
            {}, (res)=>{
              if(res.jwt) {
                let jwt = res.jwt
                localStorage.setItem("nvg_auth",jwt)
                getUserInfo(jwt)
                router.push("/courses")
              }
              else {
                this.$root.$emit("toShowTrigger", this.$root.$t('takoiejewke'), 2)
              }
            }, true)
      }
    },
  }
}
</script>