<template>
  <div class="items-center">
    <ChangeLang />
    <Button class="border rounded-lg p-3" @wasClicked="dologout()" v-if="user.firstName">
      <LogoutIcon class="w-4 h-4" />
    </Button>
    <ButtonRouter href="/sign-up" class="border border-black text-black bg-white uppercase" v-else>
      {{$t('regirsta')}}
    </ButtonRouter>
    <ButtonRouter href="/me"
                  v-if="user.firstName"
                  size="p-0"
                  class="relative flex items-center z-30">
      <Avatar :avatar="user.avatar" href="#"/>
    </ButtonRouter>
    <ButtonRouter href="/sign-in" class="tt-bg-blue text-white uppercase" v-else>
      {{$t('entetir')}}
    </ButtonRouter>
  </div>
</template>

<script>
import ChangeLang from "@/components/Control/ChangeLang";
import {LogoutIcon} from "@vue-hero-icons/outline";
import Button from "@/components/Inter/Button";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import Avatar from "@/components/Static/Avatar";
import logout from "@/common/logout";
import userInfo from "@/common/userInfo";


export default {
  name: "Naver",
  components: {
    Avatar, ChangeLang, LogoutIcon, ButtonRouter, Button
  },
  methods: {
    dologout: function () {
      this.user = {}
      logout()
      window.location.reload()
    },
  },
  data() {
    return {
      user: {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      },
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.user = {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      }
    }, 500)
    setTimeout(function () {
      that.user = {
        firstName: userInfo.firstName,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
      }
    }, 1000)
  },
}
</script>