<template>
  <div class="mb-16 pb-16">
    <Navbar/>

    <div class="grid xl:grid-cols-12 mt-28">
      <div class="xl:col-start-3 xl:col-span-8 px-0 xl:px-0">
        <h1 class="font-bold text-2xl px-4">{{this.quiz.course}}</h1>
        <h2 class="text-xl px-4">{{this.quiz.module}}</h2>

        <div class="bg-white rounded-2xl p-8 mt-10">
          <h3 class="text-2xl">{{this.quiz.question}}</h3>
          <h4 class="text-gray-400 text-lg mt-2">{{$t('choosesoemvars')}}</h4>

          <div class="flex items-start justify-center gap-10 xl:gap-6 flex-col mt-16 xl:mt-7">
            <div v-for="(value, key) in quiz.answers" :key="'answer' + key">
              <NVGRadio :theval="value.id" nameRadio="myanswer"
                        :checkedVar="!!(quiz.correct && parseInt(value.id) === quiz.answerm)"
                        :disabledVar="!![1,2].includes(quiz.correct)">
                {{value.text}}
              </NVGRadio>
              <div class="rounded-xl tt-bg-green p-4 mt-12 xl:mt-3"
                   v-if="(quiz.correct === 2 && parseInt(value.id) === quiz.answer)
                    || (quiz.correct === 1 && parseInt(value.id) === quiz.answerm)">
                {{$t('correctasnwer')}}
              </div>
              <div class="rounded-xl tt-bg-red p-4 mt-12 xl:mt-3"
                   v-else-if="quiz.correct === 2 && parseInt(value.id) === quiz.answerm">
                {{$t('correctasnwer2')}}
              </div>
            </div>
          </div>

          <Button class="border border-black bg-white text-black hover:bg-black hover:text-white
           px-5 py-3 uppercase font-bold text-sm mt-12" @wasClicked="nextTest()">
            {{$t('nexttasktest')}}
          </Button>

          <p class="text-sm text-gray-400 mt-6">
            {{$t('voppddodsa3')}} {{quiz.testNow}}/{{quiz.testTot}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import NVGRadio from "@/components/Inter/NVGRadio";
import Button from "@/components/Inter/Button";
import router from "@/router";
import nvgpost from "@/common/nvgpost";


export default {
  name: "Quiz",
  components: {
    Button,
    NVGRadio,
    Navbar
  },
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      testId: this.$route.params.testId,
      quiz: {
        course: "",
        module: "",
        question: "",
        answers: {},
        testNow: 0,
        testTot: 0,
        testNext: 0,
        testPrev: 0,
        testFirst: 0,
        correct: undefined,
        answer: undefined,
        answerm: undefined,
      }
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 1000)

    //get quiz
    nvgpost("https://api.xy.md/v1/course/quiz",
        "POST",
        {
          'lang': this.codeLang,
          'testId': this.testId,
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
          if(res.jwt) {
            this.quiz = res.data
          }
        }, true)
  },
  methods: {
    nextTest: function () {
      let answer = document.querySelector(".nvgcheck:checked").value

      nvgpost("https://api.xy.md/v1/course/answer",
          "POST",
          {
            'lang': this.codeLang,
            'testId': this.testId,
            'answer': answer,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res.jwt) {
              if(this.quiz.testNow < this.quiz.testTot) {
                router.push("/quiz/" + this.quiz.testNext)
              }
              else {
                router.push("/quiz/" + this.quiz.testFirst + "/result")
              }
            }
          }, true)
    }
  }
}
</script>