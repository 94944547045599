<template>
  <div>
    <Navbar></Navbar>
    <php></php>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import Php from "@/components/Landing/php";


export default {
  name: "Landing",
  components: {Php, Navbar}
}
</script>