import Vue from 'vue'
import App from './App.vue'
import userInfo from "@/common/userInfo"
import router from '@/router'
import GAuth from 'vue-google-oauth2'
import VueIntercom from 'vue-intercom'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import './assets/css/styles.css'
import './index.css'
import nvgpost from "@/common/nvgpost";


Vue.config.productionTip = false
Vue.use(VueI18n)

let dict = JSON.parse(localStorage.getItem('dict'))
let codeLang = localStorage.getItem('codeLang')
if(codeLang === undefined || codeLang === null)
    codeLang = "ro"

if (dict === undefined || 1===1) {
  setTimeout(function () {
    nvgpost("https://api.xy.md/v1/dict/",
        "POST",
        {
          lang: codeLang,
        },
        {}, (res) => {
          localStorage.setItem('dict', JSON.stringify(res.data))
          localStorage.setItem('codeLang', codeLang)
          userInfo.dict = {}
          userInfo.dict[codeLang] = JSON.parse(localStorage.getItem("dict"))
          const i18n = new VueI18n({
            locale: codeLang,
            fallbackLocale: codeLang,
            messages: userInfo.dict,
          })

          new Vue({
            router,
            i18n,
            render: h => h(App),
          }).$mount('#app')
        }, true)
  }, 300)
} else if (dict === null) {
  setTimeout(function () {
    nvgpost("https://api.xy.md/v1/dict/",
        "POST",
        {
          lang: codeLang,
        },
        {}, (res) => {
          localStorage.setItem('dict', JSON.stringify(res.data))
          localStorage.setItem('codeLang', codeLang)
          userInfo.dict = {}
          userInfo.dict[codeLang] = JSON.parse(localStorage.getItem("dict"))
          const i18n = new VueI18n({
            locale: codeLang,
            fallbackLocale: codeLang,
            messages: userInfo.dict,
          })

          new Vue({
            router,
            i18n,
            render: h => h(App),
          }).$mount('#app')
        }, true)
  }, 300)
} else {
  userInfo.dict = {}
  userInfo.dict[codeLang] = JSON.parse(localStorage.getItem("dict"))

  const i18n = new VueI18n({
    locale: codeLang,
    fallbackLocale: codeLang,
    messages: userInfo.dict,
  })

  new Vue({
    router,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}


userInfo.userHash = localStorage.getItem("usrha")
userInfo.firstName = localStorage.getItem("firstName")
userInfo.lastName = localStorage.getItem("lastName")
userInfo.avatar = localStorage.getItem("avatar")
userInfo.created_at = localStorage.getItem("created_at")
userInfo.email = localStorage.getItem("email")
userInfo.uid = localStorage.getItem("uid")
userInfo.codeLang = localStorage.getItem("codeLang")
userInfo.jwt = localStorage.getItem("nvg_auth")

const gauthOption = {
  clientId: '',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
Vue.use(VueMeta)
Vue.use(VueIntercom, { appId: 'xcaunnlf' });