<template>
  <div class="mb-16 pb-16">
    <Navbar></Navbar>
    <div class="grid xl:grid-cols-12 mt-28">
      <div class="xl:col-start-3 xl:col-span-8 px-0 xl:px-0">
        <h1 class="font-bold text-2xl px-4">
          {{this.lesson.title}}
        </h1>
        <p class="mt-4 px-4">
          {{this.lesson.short}}
        </p>

        <div class="my-10 bg-white rounded-lg px-0 xl:p-8">
          <div class="w-full relative">
            <div class="w-full h-full absolute top-0 left-0" @click="utube_video_clicker"></div>
            <iframe width="100%" height="500"
                    @blur="utube_video_clicker()"
                    class="mx-auto w-full hidden xl:block"
                    :src="'https://www.youtube.com/embed/' + this.video"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            <iframe width="100%" height="200"
                    class="mx-auto w-full xl:hidden"
                    @blur="utube_video_clicker()"
                    :src="'https://www.youtube.com/embed/' + this.video"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>

          <div class="flex items-center justify-start gap-4 px-4 mt-6">
            <div class="flex items-center justify-center overflow-hidden rounded-full w-12 h-12 tt-bg-blue">
              <img :src="this.lesson.author.avatar" alt="Photo Author" class="object-cover w-12">
            </div>
            <div class="flex items-start justify-center gap-2 flex-col">
              <b>{{this.lesson.author.name}}</b>
              <span class="text-sm">{{ this.lesson.author.bio }}</span>
            </div>
          </div>

          <div class="px-4 mt-12">
            <NVGCheck v-if="this.iCanGoNext || this.lesson.done" :checkedVar="!!this.lesson.done" :onChangeFunc="doneLesson">
              {{$t('cszchugitatipridev')}}
            </NVGCheck>
          </div>

          <div class="flex items-start justify-start flex-col gap-2 list-disc px-8 mt-8" v-html="this.lesson.text"></div>

          <div class="grid md:grid-cols-2 gap-2 xl:gap-4 px-4 xl:px-0 mt-11">
            <ButtonRouter :href="( !this.lesson.quizp ? '/lessons/' : '/quiz/' ) + this.lesson.past"
                          v-if="this.lesson.past"
                          class="tt-bg-blue text-white uppercase w-full flex items-center justify-center gap-3">
              <ChevronDoubleLeftIcon class="w-4 h-4" />
              <span>{{$t('prediriourok')}}</span>
            </ButtonRouter>

            <ButtonRouter :href="!this.lesson.done ? '#' : (( !this.lesson.quiz ? '/lessons/' : '/quiz/' ) + this.lesson.next)"
                          v-if="this.lesson.next"
                          :disabled="!this.iCanGoNext || !this.lesson.done"
                          :class="{
                            'cursor-default': !this.lesson.done,
                          'bg-blue-200': !this.lesson.done,
                          'tt-bg-blue': this.lesson.done}"
                          class="group relative text-white uppercase w-full flex items-center justify-center gap-3">
              <span>{{$t('oepnenrextvhapre')}}</span>
              <ChevronDoubleRightIcon class="w-4 h-4" />

              <div class="group-hover:block hidden absolute -top-8 -left-2 w-auto h-10 bg-black shadow-md rounded-lg text-white p-3">
                {{$t('snajzaarproidte')}}
              </div>
            </ButtonRouter>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import {ChevronDoubleRightIcon, ChevronDoubleLeftIcon} from "@vue-hero-icons/outline";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import nvgpost from "@/common/nvgpost";
import NVGCheck from "@/components/Inter/NVGCheck";


export default {
  name: "Lesson",
  components: {NVGCheck, ButtonRouter, Navbar, ChevronDoubleRightIcon, ChevronDoubleLeftIcon},
  data() {
    return {
      iCanGoNext: false,
      codeLang: localStorage.getItem("codeLang"),
      lessonId: this.$route.params.lessonId,
      lesson: {
        title: "",
        short: "",
        text: "",
        video: "youtu/",
        next: 0,
        quiz: false,
        quizp: false,
        done: false,
        author: {
          avatar: "",
          name: "",
          bio: "",
        }
      },
    }
  },
  computed: {
    video: function () {
      return this.lesson.video.split("youtu")[1].split("/")[1]
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")

      const listener = window.addEventListener('blur', () => {
        if (document.activeElement === document.querySelector('iframe')) {
          that.utube_video_clicker()
        }
        window.removeEventListener('blur', listener)
      })

    }, 1000)

    //Get lesson
    nvgpost("https://api.xy.md/v1/course/lesson",
        "POST",
        {
          'lang': this.codeLang,
          'lessonId': this.lessonId,
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
          if(res.jwt) {
            this.lesson = res.data
          }
        }, true)
  },
  methods: {
    utube_video_clicker: function (e) {
      e.target.remove()
      setTimeout(function () {
        this.iCanGoNext = true
      }.bind(this), 10000)
    },
    doneLesson: function (e) {
      nvgpost("https://api.xy.md/v1/course/lesson_done",
          "POST",
          {
            'done': e.target.checked,
            'lessonId': this.lessonId,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
          ()=>{
            this.lesson.done = e.target.checked
          }, true)
    }
  },
}
</script>

<style>
  .list-disc ul {
    list-style-type: disc;
    transform: translateX(20px);
  }

  .list-disc p {
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
  }
</style>