<template>
  <div>
    <div class="grid xl:grid-cols-12 xl:h-screen">
      <div class="xl:col-span-6 col-span-12 overflow-hidden">
        <img src="../assets/landing/authimage.png" alt="Login image"
             class="w-full h-96 xl:h-full object-cover select-none">
      </div>
      <div class="xl:col-start-8 xl:col-span-4 col-span-12 mt-10 xl:mt-0 pb-48 xl:pb-0 px-6 xl:px-0
       flex items-start justify-center flex-col mb-24">
        <router-link to="/" class="flex items-center gap-2">
          <img src="../assets/logo.svg" alt="Logo XY Academy" class="w-32">
        </router-link>
        <div class="text-2xl font-bold mt-5">
          {{$t('voicdw')}}
        </div>
        <div class="w-full mt-6">
          <div>
            <label class="text-gray-400 block">Email</label>
            <Input id="email" type="email" itype="Lg" :vkeyup="logInWithXy"
                   class="w-full mt-3" placeholder="John.snow@gmail.com" />
          </div>
          <div class="mt-5">
            <label class="text-gray-400 block">{{$t('psadapsdwqd')}}</label>
            <Input id="password" type="password" itype="Lg" minlength="6"
                   :vkeyup="logInWithXy" class="w-full mt-3" placeholder="*********" />
          </div>
          <div class="flex items-center justify-between mt-8">
            <label class="inline-flex items-center rounded-xl">
              <input type="checkbox" id="rememberMe">
              <span class="ml-2">{{$t('zapomti')}}</span>
            </label>
            <router-link to="/forgot-password" class="text-sm tt-text-blue">
              {{$t('xzabnilitfd')}}
            </router-link>
          </div>
          <div class="mt-8">
            <Button @wasClicked="logInWithXy()" class="bg-black text-white w-full" size="Lg">
              {{$t('entetir')}}
            </Button>
            <router-link to="/sign-up" class="block tt-text-blue text-sm mt-6">
              {{$t('regirsta')}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import getUserInfo from "@/common/getUserInfo";
import nvgpost from "@/common/nvgpost";
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";


export default {
  name: "SignIn",
  components: {
    Button,
    Input,
  },
  methods: {
    async logInWithXy()  {
      let email = document.getElementById("email").value
      let password = document.getElementById("password").value

      if(email && password) {
        nvgpost("https://api.xy.md/v1/auth/xy",
            "POST",
            {
              'email': email,
              'password': password,
            },
            {}, (res)=>{
              if(res.jwt) {
                let jwt = res.jwt
                localStorage.setItem("nvg_auth",jwt)
                getUserInfo(jwt)
                setTimeout(function () {
                  this.$root.$emit("douEm")
                  this.$intercom.boot()
                }.bind(this), 1000)
                router.push("/courses")
              }
              else {
                if(res.error==="1") {
                  this.$root.$emit("toShowTrigger", this.$root.$t('takoiejewke2'), 2)
                }
                else if(res.error==="2") {
                  this.$root.$emit("toShowTrigger", this.$root.$t('takoiejewke3'), 2)
                }
                else if(res.error==="3") {
                  this.$root.$emit("toShowTrigger", this.$root.$t('takoiejewke4'), 2)
                }
                else if(res.error==="4") {
                  this.$root.$emit("toShowTrigger", this.$root.$t('vostanovlenaieura'), 2)
                }
                else {
                  this.$root.$emit("toShowTrigger", this.$root.$t('takoiejewke2'), 2)
                }
              }
            }, true)
      }
    },
  }
}
</script>