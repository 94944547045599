<template>
  <div class="px-3 py-1.5 tt-bg-blue uppercase rounded-lg text-white">
    <span class="font-bold font-base">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "Badge"
}
</script>