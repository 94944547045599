<template>
  <section class="container mx-auto py-32">
    <router-link to="/" class="rounded-2xl tt-bg-green text-white inline-block py-4 px-6 mb-24">
      {{ $t('back') }}
    </router-link>

    <p v-html="$t('terms')"></p>

    <p><br></p>
    <p><br></p>
    <p><br></p>
  </section>
</template>

<script>
export default {
  name: "Terms",
}
</script>