<template>
  <div>
    <div class="grid xl:grid-cols-12 xl:h-screen">
      <div class="xl:col-span-6 col-span-12 overflow-hidden">
        <img src="../assets/landing/authimage.png" alt="Login image"
             class="w-full h-96 xl:h-full object-cover select-none">
      </div>
      <div class="xl:col-start-8 xl:col-span-4 col-span-12 mt-10 xl:mt-0 pb-48 xl:pb-0 px-6 xl:px-0
       flex items-start justify-center flex-col mb-24">
        <router-link to="/" class="flex items-center gap-2">
          <img src="../assets/logo.svg" alt="Logo XY Academy" class="w-32">
        </router-link>
        <div class="w-full mt-6">
          <div>
            <label class="text-gray-400 block">{{$t('newqpdsaofsakeqo')}}</label>
            <Input id="password" type="password" itype="Lg" minlength="6"
                   class="w-full mt-3" placeholder="************" />
          </div>
          <div class="mt-8">
            <label class="text-gray-400 block">{{$t('newqpdsaofsakeqo2')}}</label>
            <Input id="password2" type="password" itype="Lg" minlength="6"
                   class="w-full mt-3" placeholder="************" />
          </div>
          <div class="mt-8">
            <Button @wasClicked="recoverPsw()" class="bg-black text-white w-full" size="Lg">
              {{$t('recoverylogdo')}}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Inter/Button";
import Input from "@/components/Inter/Input";
import nvgpost from "@/common/nvgpost";
import router from "@/router";


export default {
  name: "RecoverPassword",
  components: {
    Button, Input
  },
  data() {
    return {
      uto: this.$route.params.uto,
      vto: this.$route.params.vto,
      codeLang: localStorage.getItem("codeLang")
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 1000)
  },
  methods: {
    async recoverPsw()  {
      let password1 = document.getElementById("password").value
      let password2 = document.getElementById("password2").value

      if(password1 && password2 && password1 === password2) {
        nvgpost("https://api.xy.md/v1/password/recover",
            "POST",
            {
              'password1': password1,
              'password2': password2,
              'uto': this.uto,
              'vto': this.vto,
              'lang': this.codeLang,
            },
            {}, ()=>{
              router.push("/sign-in")
            }, true)
      }
    },
  }
}
</script>