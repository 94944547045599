<template>
  <textarea class="tt-text-black text-sm rounded-xl border focus:tt-border-blue px-4 py-3
                select-text" v-on:keyup.enter="vkeyup()" v-model="currentValue" @input="onchangefunc"></textarea>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    value: String,
    vkeyup: {
      type: Function,
      default: () => {

      }
    },
    onchangefunc: {
      type: Function,
      default: () => {

      }
    },
  },
  computed: {
    currentValue: function () {
      return this.getDefaultValue()
    }
  },
  methods: {
    getDefaultValue() {
      if (this.$slots.default && this.$slots.default.length) {
        return this.$slots.default[0].text
      }
      return this.value
    }
  }
}
</script>
