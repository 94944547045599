<template>
  <div>
    <Button @wasClicked="doMenu()">
      <MenuIcon class="w-5 h-5" />
    </Button>

    <div class="absolute top-0 right-0 w-full h-screen bg-white transform transition z-50"
         :class="openedMenu ? '' : 'translate-x-960'">
      <div class="flex items-center justify-between p-4">
        <Logo />
        <Button @wasClicked="doMenu()">
          <MenuIcon class="w-5 h-5" />
        </Button>
      </div>

      <div class="mt-5">
        <Links class="flex" />
        <Naver class="flex justify-center gap-12 mt-10" />
      </div>
    </div>
  </div>
</template>

<script>
import {MenuIcon} from "@vue-hero-icons/outline";
import Button from "@/components/Inter/Button";
import Logo from "@/components/Static/Logo";
import Links from "@/components/Control/Links";
import Naver from "@/components/Control/Naver";


export default {
  name: "MobMenu",
  components: {
    Naver,
    Links,
    Logo,
    Button,
    MenuIcon
  },
  data() {
    return {
      openedMenu: false,
    }
  },
  methods: {
    doMenu: function () {
      this.openedMenu = !this.openedMenu

      if(this.openedMenu) {
        document.body.style.overflow = "hidden"
      }
      else {
        document.body.style.overflow = "auto"
      }
    }
  }
}
</script>
<style scoped>
  .translate-x-960 {
    transform: translateX(10000px);
  }
</style>