<template>
  <div class="mb-16 pb-16">
    <Navbar></Navbar>

    <div class="grid xl:grid-cols-12 mt-32">
      <div class="xl:col-start-3 xl:col-span-8 px-0 xl:px-0 flex items-center justify-center gap-16 flex-col">
        <div class="w-full" v-for="(value, index) in webinars" :key="'video'+index">
          <h1 class="text-3xl font-medium my-8">
            {{value.name}}
          </h1>
          <iframe width="100%" height="500"
                  class="mx-auto w-full hidden xl:block"
                  :src="'https://www.youtube.com/embed/' + value.youtube"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe width="100%" height="200"
                  class="mx-auto w-full xl:hidden"
                  :src="'https://www.youtube.com/embed/' + value.youtube"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import nvgpost from "@/common/nvgpost";


export default {
  name: "Course",
  components: {Navbar},
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      webinars: [],
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)

    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 1000)

    nvgpost("https://api.xy.md/v1/webinars",
        "POST",
        {
          'lang': this.codeLang,
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),},
        (res)=>{
            this.webinars = res.data
        }, true)
  },
}
</script>