<template>
  <div class="bg-white rounded-lg" :class="place === 'profile' ? 'p-2' : 'p-6'">
    <h4 class="font-bold flex items-center justify-between">
      <span class="text-xl">
        {{ $t('progroewsjiaejqwja') }}
      </span>
      <span>
        {{pData.done}}/{{pData.total}}
      </span>
    </h4>
    <div class="mt-2">
      {{pData.module}}
    </div>

    <div class="w-full tt-bg-light rounded-full h-1 mt-8">
      <div class="tt-bg-darkblue h-1 rounded-full" :style="'width: '+line+'%'"></div>
    </div>

    <div class="flex items-center justify-start gap-3 mt-6">
      <ExternalLinkIcon class="w-4 h-6 tt-text-darkblue" />
      <span class="font-bold text-base">
        {{$t('nexttask')}}
      </span>
    </div>

    <div class="mt-6">
      <ButtonRouter :href="'/lessons/' + pData.taskId" class="border rounded-lg gap-4 w-full">
        <span class="text-sm font-normal">
          {{pData.task}}
        </span>
        <span class="text-sm font-normal tt-text-darkblue p-1">
          {{$t('otktitit')}}
        </span>
      </ButtonRouter>
    </div>
  </div>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";
import {ExternalLinkIcon} from "@vue-hero-icons/outline";


export default {
  name: "Progress",
  components: {
    ButtonRouter,
    ExternalLinkIcon,
  },
  props: {
    place: {
      type: String,
      default: "course"
    },
    pData: {
      type: Object,
      default: ()=>{
        return {
          task: "",
          taskId: "",
          module: "",
          done: "",
          total: ""
        }
      },
    }
  },
  computed: {
    line: function () {
      return Math.round((this.pData.done/this.pData.total)*100)
    }
  }
}
</script>