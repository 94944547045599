<template>
  <div class="mb-16 pb-16">
    <Navbar></Navbar>
    <Intro :resInfo="resInfo" class="mt-17"
           v-if="!!Object.keys(resInfo).length"></Intro>
    <Intro :resInfo="resInfoPublic" class="mt-17"
           v-else-if="!!Object.keys(resInfoPublic).length"></Intro>
    <PublicCourse class="transform -translate-y-8 xl:translate-y-0 xl:mt-8"
                  v-if="!!Object.keys(resInfoPublic).length" :resInfo="resInfoPublic" />
    <Materials :resMaterials="resMaterials" class="transform -translate-y-8 xl:translate-y-0 xl:mt-8"
           v-if="!!Object.keys(resMaterials).length" :isPublic="!!Object.keys(resInfoPublic).length"></Materials>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import Intro from "@/components/Block/Intro";
import Materials from "@/components/Block/Materials";
import nvgpost from "@/common/nvgpost";
import PublicCourse from "@/components/Block/PublicCourse";

export default {
  name: "Course",
  components: {PublicCourse, Materials, Intro, Navbar},
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      courseId: this.$route.params.courseId,
      resInfo: {},
      resMaterials: {},
      resInfoPublic: {},
      resMaterialsPublic: {},
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)

    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 1000)

    //Get course

    if(localStorage.getItem("nvg_auth"))
    {
      nvgpost("https://api.xy.md/v1/course/info",
          "POST",
          {
            'lang': this.codeLang,
            'courseId': this.courseId,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res.jwt) {
              this.resInfo = res.data.info
              this.resMaterials = res.data.materials
            }
          }, true)
    }
    else {
      nvgpost("https://api.xy.md/v1/course/public",
          "POST",
          {
            'lang': this.codeLang,
            'courseId': this.courseId,
          },
          {}, (res)=>{
            if(res.data) {
              this.resInfoPublic = res.data.info
              this.resMaterials = res.data.materials
              this.resInfoPublic.id = this.courseId
            }
          }, true)
    }
  },
}
</script>