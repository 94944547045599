<template>
  <button class="text-sm font-medium rounded-lg select-none transition hover:opacity-70"
          :class="
          (this.size === 'Lg' ? 'py-4 px-6' : '') + ' '
          + (this.size === 'Sm' ? 'py-3 px-4' : '') + ' '
          + (this.size === '0' ? 'p-0' : '') + ' '
          + this.flex + ' '
          "
          @click="$emit('wasClicked')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    size: {
      type: String,
      default: "Sm",
    },
    flex: {
      type: String,
      default: "inline-flex items-center justify-center",
    }
  }
}
</script>
