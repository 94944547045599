<template>
  <div class="mb-16 pb-16">
    <Navbar />

    <div class="grid xl:grid-cols-12 mt-28">
      <div class="xl:col-start-3 xl:col-span-8 px-0 xl:px-0">
        <h1 class="font-bold text-2xl px-4">{{this.quiz.course}}</h1>
        <h2 class="text-xl px-4">{{this.quiz.module}}</h2>

        <div class="rounded-xl bg-white p-8 mt-8 pt-10">
          <h1 class="flex items-center justify-center tt-text-blue font-semibold text-2xl xl:text-7xl">
            {{this.quiz.success}}/{{this.quiz.total}}
          </h1>
          <h2 class="flex items-center justify-center text-center font-bold text-xl mt-1">
            {{$t('viewqrfaotrfewfvto')}}
          </h2>

          <div class="border rounded-2xl p-3 mt-10">
            <router-link :to="'/quiz/'+quiz.mids[value].id"
                         v-for="(value, index) in Object.keys(quiz.mids)" :key="'qres'+index"
                         class="inline-flex items-center justify-center rounded-lg w-9 h-9 text-sm mr-2 mb-2"
               :class="quiz.nitems.includes(quiz.mids[value].id) ? 'tt-bg-blue text-white' : 'tt-bg-red text-black'">
              {{index+1}}
            </router-link>
          </div>
        </div>

        <div class="xl:col-start-2 xl:col-span-3 flex items-center justify-center gap-3 mt-8 px-4 xl:px-0">
          <Button @wasClicked="repeatTest('/quiz/' + testId, testId)"
                  flex="flex items-center justify-center"
                       class="gap-2 bg-white font-bold text-black xl:px-8 xl:py-5 rounded-lg uppercase">
            <ArrowNarrowLeftIcon class="flex-none w-4 h-4" />
            <span>{{$t('yenaznotoznvoot')}}</span>
          </Button>
          <router-link
              v-if="this.quiz.nextLesson"
              :to="'/lessons/' + this.quiz.nextLesson"
                       class="flex items-center justify-center gap-2 tt-bg-blue font-bold text-white
                        px-4 py-3 xl:px-8 xl:py-5 rounded-lg uppercase">
            <span>{{$t('snewqnesaotrema')}}</span>
            <ArrowRightIcon class="flex-none w-4 h-4" />
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import nvgpost from "@/common/nvgpost";
import {ArrowNarrowLeftIcon, ArrowRightIcon} from "@vue-hero-icons/outline";
import Button from "@/components/Inter/Button";
import router from "@/router";


export default {
  name: "Results",
  components: {Button, Navbar, ArrowNarrowLeftIcon, ArrowRightIcon},
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      testId: this.$route.params.testId,
      quiz: {
        course: "",
        module: "",
        success: 0,
        total: 0,
        mids: {},
        nitems: [],
        nextLesson: 0,
      }
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 1000)

    //get quiz
    nvgpost("https://api.xy.md/v1/course/qresult",
        "POST",
        {
          'lang': this.codeLang,
          'testId': this.testId,
        },
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
          if(res.jwt) {
            this.quiz = res.data
          }
        }, true)
  },
  methods: {
    repeatTest: function (url, tid) {
      nvgpost("https://api.xy.md/v1/course/repeat_quiz",
          "POST",
          {
            'lang': this.codeLang,
            'testId': tid,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=>{
            if(res.jwt) {
              router.push(url)
            }
          }, true)
    }
  }
}
</script>