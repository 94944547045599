<template>
  <router-link to="/">
    <img src="../../assets/logo.svg" alt="XY Academy Logo" :class="this.img_class">
  </router-link>
</template>

<script>
export default {
  name: "Logo",
  props: {
    img_class: {
      type: String,
      default: "w-32"
    },
    h_class: {
      type: String,
      default: "text-lg tt-text-black"
    },
  }
}
</script>