import Vue from "vue"
import VueRouter from "vue-router"
import Terms from "@/views/Terms"
import Course from "@/views/Course"
import Landing from "@/views/Landing"
import SignUp from "@/views/SignUp"
import SignIn from "@/views/SignIn"
import ForgotPassword from "@/views/ForgotPassword"
import RecoverPassword from "@/views/RecoverPassword"
import Lesson from "@/views/Lesson"
import Quiz from "@/views/Quiz";
import Results from "@/views/Results";
import Me from "@/views/Me";
import Settings from "@/views/Settings";
import Library from "@/views/Library";


Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: "/",
            component: Landing
        },
        {
            path: "/sign-up",
            component: SignUp
        },
        {
            path: "/sign-in",
            component: SignIn
        },
        {
            path: "/forgot-password",
            component: ForgotPassword
        },
        {
            path: "/recover/:uto/:vto",
            component: RecoverPassword
        },
        {
            path: "/courses/:courseId",
            component: Course
        },
        {
            path: "/quiz/:testId",
            component: Quiz
        },
        {
            path: "/quiz/:testId/result",
            component: Results
        },
        {
            path: "/courses",
            redirect: '/courses/1'
        },
        {
            path: "/me",
            component: Me
        },
        {
            path: "/library",
            component: Library
        },
        {
            path: "/settings",
            component: Settings
        },
        {
            path: "/lessons/:lessonId",
            component: Lesson
        },
        {
            path: "/terms-and-privacy-policy",
            component: Terms
        },
        {
            path: "/privacy",
            component: Terms
        },
        {
            path: "/terms",
            component: Terms
        },


        { path: '/:catchAll(.*)', redirect: '/' }
    ]
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['', 'terms-and-privacy-policy', 'privacy', 'terms', 'sign-in', 'sign-up', 'forgot-password',
        'recover', 'courses']
    let turl = to.path.split("/")[1]
    const authRequired = !publicPages.includes(turl)
    const loggedIn = !!localStorage.getItem('nvg_auth')

    document.body.style.overflow = "auto"

    //Return user to the page before he got signin/up page
    let temp = localStorage.getItem("backcomeUrl")
    if ((authRequired && !loggedIn))
    {
        localStorage.setItem("backcomeUrl", to.path)
        return next('/sign-in')
    }
    else if (temp && !!loggedIn)
    {
        localStorage.removeItem("backcomeUrl")
        return next(temp)
    }
    else if (['sign-in', 'sing-up'].includes(turl) && !!loggedIn)
    {
        localStorage.removeItem("backcomeUrl")
        return next('/courses')
    }
    next()
})

export default router