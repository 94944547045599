<template>
  <div class="items-center justify-center gap-4">
    <ButtonRouter href="/library" v-if="user">{{$t('blbitotre')}}</ButtonRouter>
    <ButtonRouter href="/courses">{{$t('hemanjn')}}</ButtonRouter>
    <a href="https://xy.md/ro/portfolio"
       target="_blank"
       class="text-sm font-medium rounded-lg transition hover:opacity-70 select-none
       px-4 py-3 inline-flex items-center justify-center">
      {{$t('startupsalcatalo')}}
    </a>
  </div>
</template>

<script>
import ButtonRouter from "@/components/Inter/ButtonRouter";


export default {
  name: "Links",
  components: {
    ButtonRouter,
  },
  data() {
    return {
      user: localStorage.getItem("nvg_auth"),
    }
  }
}
</script>