import nvgpost from "@/common/nvgpost"
import md5 from "md5"
import userInfo from "@/common/userInfo"

let getUserInfo = function (mjwt, tryyer) {

    if(tryyer===undefined)
    {
        setTimeout(function () {
            getUserInfo(mjwt, 2)
        },3000)
    }

    mjwt = mjwt === undefined ? userInfo.jwt : mjwt

    nvgpost("https://api.xy.md/v1/users/info",
        "POST",
        {},
        {'Authorization': 'Bearer '+mjwt,}, (res)=> {
            if(res.firstName === undefined || res.lastName === undefined)
                console.log("Some error")

            if(res.firstName !== undefined && res.lastName !== undefined) {
                localStorage.setItem("firstName",res.firstName)
                localStorage.setItem("lastName",res.lastName)
                localStorage.setItem("avatar",res.avatar)
                localStorage.setItem("email",res.email)
                localStorage.setItem("created_at",res.created_at)
                localStorage.setItem("uid",res.uid)
                localStorage.setItem("usrha",md5(res.firstName+res.lastName+mjwt))

                userInfo.userHash = localStorage.getItem("usrha")
                userInfo.firstName = localStorage.getItem("firstName")
                userInfo.lastName = localStorage.getItem("lastName")
                userInfo.email = localStorage.getItem("email")
                userInfo.created_at = localStorage.getItem("created_at")
                userInfo.avatar = localStorage.getItem("avatar")
                userInfo.uid = localStorage.getItem("uid")
                userInfo.jwt = localStorage.getItem("nvg_auth")
            }
        })
    
}

export default getUserInfo