<template>
  <section class="grid xl:grid-cols-12 xl:px-8">
    <div class="xl:col-span-6 xl:col-start-2 bg-white rounded-lg shadow-md px-5 xl:px-8 py-6">
      <h2 class="text-2xl font-bold">
        <strong>{{$t('cemvriotv')}}</strong>
      </h2>

      <ul class="list-disc mt-6" v-html="this.resInfo.learn"></ul>
    </div>

    <div class="xl:col-span-6 xl:col-start-2 bg-white rounded-lg shadow-md px-5 xl:px-8 py-6 mt-4">
      <h2 class="text-base font-bold">
        <strong>{{$t('kcocmdapfkre')}}</strong>
      </h2>
      <p class="text-sm mt-2">
        {{$t('focmptostext')}}
      </p>

      <img src="../../assets/parts.png" alt="Companies logos" class="mt-6 w-full">
    </div>
  </section>
</template>

<script>
export default {
  name: "PublicCourse",
  props: {
    resInfo: {
      type: [Object, Function, Array],
      default: function () {
        return {
          learn: "",
        }
      }
    }
  }
}
</script>