<template>
  <div class="mb-16 pb-16">
    <Navbar />

    <div class="grid xl:grid-cols-12 mt-20">
      <div class="xl:col-start-3 col-span-12 xl:col-span-8 p-5 xl:p-12">
        <div class="bg-white shadow-sm rounded-lg p-8">
          <div>
            <span class="text-sm text-gray-400">
              {{$t('oyirpewpriuce')}}
            </span>

            <input type="file" accept="image/*" onchange=""
                   id="avatarFile" class="hidden"/>

            <div class="flex items-center justify-start gap-6 mt-4">
              <img :src="this.user.avatar" :alt="this.user.firstName+' '+this.user.lastName+' Photo'"
                   class="rounded-full w-16 h-16 ava" />

              <button class="border border-black transition hover:bg-black hover:text-white rounded-xl px-4 py-3"
                      onclick="">
                {{$t('changeavafafar')}}
              </button>
            </div>
          </div>

          <hr class="my-6">

          <div class="grid xl:grid-cols-2 gap-4 xl:w-2/3">
            <div class="flex items-start justify-center gap-2 flex-col">
              <span class="text-sm text-gray-400">{{$t('sseconame')}}</span>
              <Input id="fname" autocomplete="off" type="text" :value="this.user.firstName" class="w-full" />
            </div>
            <div class="flex items-start justify-center gap-2 flex-col">
              <span class="text-sm text-gray-400">{{$t('sseconame2')}}</span>
              <Input id="lname" autocomplete="off" type="text" :value="this.user.lastName" class="w-full" />
            </div>
            <div>
              <span class="text-sm text-gray-400">{{$t('sexscas')}}</span>
              <select id="gender" class="px-4 py-3 border rounded-xl mt-4 w-full">
                <option value="1" :selected="this.user.sex==='1'">{{$t('madlsaew1')}}</option>
                <option value="0" :selected="this.user.sex==='0'">{{$t('madlsaew2')}}</option>
                <option value="2" :selected="this.user.sex==='2'">{{$t('madlsaew3')}}</option>
              </select>
            </div>
            <div class="flex items-start justify-center gap-2 flex-col">
              <span class="text-sm text-gray-400">{{$t('lcoation')}}</span>
              <Input id="location" autocomplete="off" type="text" class="w-full" :value="this.user.location" />
            </div>
            <div class="flex items-start justify-center gap-2 flex-col xl:col-span-2">
              <span class="text-sm text-gray-400">{{$t('biofdsi2')}}</span>
              <Textarea rows="5" class="w-full" id="bio" :value="this.user.bio"></Textarea>
            </div>

            <div class="flex items-start justify-center gap-2 flex-col xl:col-span-2">
              <span class="text-sm text-gray-400">{{$t('tiemzojeneww')}}</span>
              <select class="px-4 py-3 border rounded-xl mt-4 w-full" id="timezone">
                <option value="-12:00" :selected="this.user.timezone==='-12:00'">(GMT -12:00) Eniwetok, Kwajalein</option>
                <option value="-11:00" :selected="this.user.timezone==='-11:00'">(GMT -11:00) Midway Island, Samoa</option>
                <option value="-10:00" :selected="this.user.timezone==='-10:00'">(GMT -10:00) Hawaii</option>
                <option value="-09:50" :selected="this.user.timezone==='-09:50'">(GMT -9:30) Taiohae</option>
                <option value="-09:00" :selected="this.user.timezone==='-09:00'">(GMT -9:00) Alaska</option>
                <option value="-08:00" :selected="this.user.timezone==='-08:00'">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                <option value="-07:00" :selected="this.user.timezone==='-07:00'">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                <option value="-06:00" :selected="this.user.timezone==='-06:00'">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                <option value="-05:00" :selected="this.user.timezone==='-05:00'">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                <option value="-04:50" :selected="this.user.timezone==='-04:50'">(GMT -4:30) Caracas</option>
                <option value="-04:00" :selected="this.user.timezone==='-04:00'">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                <option value="-03:50" :selected="this.user.timezone==='-03:50'">(GMT -3:30) Newfoundland</option>
                <option value="-03:00" :selected="this.user.timezone==='-03:00'">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                <option value="-02:00" :selected="this.user.timezone==='-02:00'">(GMT -2:00) Mid-Atlantic</option>
                <option value="-01:00" :selected="this.user.timezone==='-01:00'">(GMT -1:00) Azores, Cape Verde Islands</option>
                <option value="+00:00" :selected="this.user.timezone==='+00:00' || !this.user.timezone">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                <option value="+01:00" :selected="this.user.timezone==='+01:00'">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                <option value="+02:00" :selected="this.user.timezone==='+02:00'">(GMT +2:00) Kaliningrad, South Africa</option>
                <option value="+03:00" :selected="this.user.timezone==='+03:00'">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                <option value="+03:50" :selected="this.user.timezone==='+03:50'">(GMT +3:30) Tehran</option>
                <option value="+04:00" :selected="this.user.timezone==='+04:00'">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                <option value="+04:50" :selected="this.user.timezone==='+04:50'">(GMT +4:30) Kabul</option>
                <option value="+05:00" :selected="this.user.timezone==='+05:00'">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                <option value="+05:50" :selected="this.user.timezone==='+05:50'">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                <option value="+05:75" :selected="this.user.timezone==='+05:75'">(GMT +5:45) Kathmandu, Pokhara</option>
                <option value="+06:00" :selected="this.user.timezone==='+06:00'">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                <option value="+06:50" :selected="this.user.timezone==='+06:50'">(GMT +6:30) Yangon, Mandalay</option>
                <option value="+07:00" :selected="this.user.timezone==='+07:00'">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                <option value="+08:00" :selected="this.user.timezone==='+08:00'">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                <option value="+08:75" :selected="this.user.timezone==='+08:75'">(GMT +8:45) Eucla</option>
                <option value="+09:00" :selected="this.user.timezone==='+09:00'">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                <option value="+09:50" :selected="this.user.timezone==='+09:50'">(GMT +9:30) Adelaide, Darwin</option>
                <option value="+10:00" :selected="this.user.timezone==='+10:00'">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                <option value="+10:50" :selected="this.user.timezone==='+10:50'">(GMT +10:30) Lord Howe Island</option>
                <option value="+11:00" :selected="this.user.timezone==='+11:00'">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                <option value="+11:50" :selected="this.user.timezone==='+11:50'">(GMT +11:30) Norfolk Island</option>
                <option value="+12:00" :selected="this.user.timezone==='+12:00'">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                <option value="+12:75" :selected="this.user.timezone==='+12:75'">(GMT +12:45) Chatham Islands</option>
                <option value="+13:00" :selected="this.user.timezone==='+13:00'">(GMT +13:00) Apia, Nukualofa</option>
                <option value="+14:00" :selected="this.user.timezone==='+14:00'">(GMT +14:00) Line Islands, Tokelau</option>
              </select>
            </div>

            <div class="flex items-start justify-center gap-2 flex-col">
              <span class="text-sm text-gray-400">Linkedin</span>
              <Input id="linkedin" autocomplete="off" type="text" class="w-full" :value="this.user.linkedin" />
            </div>
            <div class="flex items-start justify-center gap-2 flex-col">
              <span class="text-sm text-gray-400">Twitter</span>
              <Input id="twitter" autocomplete="off" type="text" class="w-full" :value="this.user.twitter" />
            </div>
            <div class="flex items-start justify-center gap-2 flex-col">
              <span class="text-sm text-gray-400">Facebook</span>
              <Input id="facebook" autocomplete="off" type="text" class="w-full" :value="this.user.facebook" />
            </div>
            <div class="flex items-start justify-center gap-2 flex-col">
              <span class="text-sm text-gray-400">Instagram</span>
              <Input id="instagram" autocomplete="off" type="text" class="w-full" :value="this.user.instagram" />
            </div>

            <Button @wasClicked="updateProfile()" size="Lg"
                    class="rounded-lg tt-bg-blue text-white w-full mt-6">
              {{$t('savefopfoif1')}}
            </Button>
          </div>

          <div class="grid xl:grid-cols-2 gap-4 xl:w-2/3 mt-32" v-if="this.user.email_opt_in===1">
            <ButtonRouter href="#"
               class="rounded-lg bg-red-300 text-white w-full py-4 px-6 font-medium text-sm mt-6">
              {{$t('optisdastia')}}
            </ButtonRouter>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Control/Navbar";
import userInfo from "@/common/userInfo";
import nvgpost from "@/common/nvgpost";
import Input from "@/components/Inter/Input";
import Button from "@/components/Inter/Button";
import ButtonRouter from "@/components/Inter/ButtonRouter";
import Textarea from "@/components/Inter/Textarea";
import router from "@/router";


export default {
  name: "Settings",
  components: {Textarea, Navbar, Input, Button, ButtonRouter},
  data() {
    return {
      codeLang: localStorage.getItem("codeLang"),
      user: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        uid: userInfo.uid,
        avatar: userInfo.avatar,
        sex: userInfo.sex,
        location: userInfo.location,
        bio: userInfo.bio,
        linkedin: userInfo.linkedin,
        twitter: userInfo.twitter,
        facebook: userInfo.facebook,
        instagram: userInfo.instagram,
        email_opt_in: userInfo.email_opt_in,
        timezone: userInfo.timezone,
        jwt: "",
      },
    }
  },
  mounted() {
    let that = this
    setTimeout(function () {
      that.codeLang = localStorage.getItem("codeLang")
    }, 500)

    nvgpost("https://api.xy.md/v1/users/info",
        "POST",
        {},
        {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, (res)=> {
          that.user = res
        })
  },
  methods: {
    updateProfile: function () {
      nvgpost("https://api.xy.md/v1/users/save",
          "POST",
          {
            'lang': this.codeLang,
            'fname': document.getElementById("fname").value,
            'lname': document.getElementById("lname").value,
            'gender': document.getElementById("gender").value,
            'location': document.getElementById("location").value,
            'bio': document.getElementById("bio").value,
            'timezone': document.getElementById("timezone").value,
            'linkedin': document.getElementById("linkedin").value,
            'twitter': document.getElementById("twitter").value,
            'facebook': document.getElementById("facebook").value,
            'instagram': document.getElementById("instagram").value,
          },
          {'Authorization': 'Bearer '+localStorage.getItem("nvg_auth"),}, ()=>{
            router.push("/me")
          }, true)
    }
  }
}
</script>